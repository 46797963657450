<template>
  <div>
    <div class="header">
      <b-container>
        <b-row align-v="center" class="header__wrapper">
          <b-col cols="12">
            <b-row class="header__title">
              <b-col cols="12" md="8" class="header__title_1">
                <h1>ПОДНИМИ УРОВЕНЬ</h1>
              </b-col>

              <b-col cols="12" md="8">
                <h1>СВОЕГО ДОХОДА <br />И МОТИВАЦИИ</h1>
              </b-col>
            </b-row>

            <b-row class="row_header_text">
              <b-col cols="12" md="8" class="point">
                <ul class="header__text">
                  <li class="text__item">
                    <h5>Первые результаты уже через 14 дней.</h5>
                  </li>

                  <li class="text__item">
                    <h5>
                      Начни действовать, используя<br />инструменты долларовых
                      миллионеров.
                    </h5>
                  </li>

                  <li class="text__item">
                    <h5>Достигай, играя.</h5>
                  </li>
                </ul>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="3" class="btn1">
                <button
                  class="welcome_button"
                  @click="$router.push({ name: 'Tasks' })"
                >
                  Начать изменения
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--block_a-->
    <div class="block_a">
      <b-container>
        <b-row align-h="center">
          <b-col cols="auto">
            <h2 class="title2">ЧТО ПРИВЕЛО ТЕБЯ СЮДА?</h2>
          </b-col>
        </b-row>

        <b-row class="row_a1">
          <b-col cols="12" md="3" class="col_a col-md-3 justify-content-center">
            <img class="img_a" src="../assets/img/LandingPage/img_a1.png" />

            <p class="txt_a">Ты замечал, что не все тренинги работают</p>
          </b-col>

          <b-col cols="12" md="3" class="col_a">
            <img class="img_a" src="../assets/img/LandingPage/img_a2.png" />

            <p class="txt_a">
              Ты знаешь в сотни раз больше, чем применяешь на практике
            </p>
          </b-col>

          <b-col cols="12" md="3" class="col_a">
            <img class="img_a" src="../assets/img/LandingPage/img_a3.png" />

            <p class="txt_a">
              Ты мечтаешь создать что-то действительно значимое для семьи и мира
            </p>
          </b-col>

          <b-col cols="12" md="3" class="col_a">
            <img class="img_a" src="../assets/img/LandingPage/img_a4.png" />

            <p class="txt_a">
              Ты постоянно что-то делаешь, но не получаешь результата
            </p>
          </b-col>
        </b-row>

        <b-row class="row_a2">
          <b-col cols="12">
            <h2 class="text_a2">
              В ЭТИХ МЫСЛЯХ<br />
              <span class="red">ТЫ НЕ ОДИНОК</span>
            </h2>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--block_b-->
    <div class="block_b">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h3 class="title_black">
              ПОДТВЕРЖДЕНИЕ ТОМУ<br />
              <span class="red">-ИСТОРИИ НАШИХ КЛИЕНТОВ</span>
            </h3>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="12" md="7" class="col_video_b">
            <div class="embed-responsive embed-responsive-16by9">
              <!--<iframe class="embed-responsive-item"  src="https://www.youtube.com/embed/IZpOymcu-fU" frameborder="0" allowfullscreen></iframe>-->
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/bhCvaDWEn00"
                frameborder="0"
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--block_c-->
    <div class="block_c">
      <b-container>
        <b-row align-h="center">
          <b-col cols="auto">
            <h3 class="redline_text">6 ПРИЧИН</h3>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="auto">
            <h4 class="title4_c">почему люди не достигают своих целей</h4>
          </b-col>
        </b-row>

        <b-row class="title4_c_last" align-h="center">
          <b-col cols="auto">
            <h4 class="title4_c">
              и довольствуются посредственными результатами
            </h4>
          </b-col>
        </b-row>

        <div class="card_numb">
          <b-row>
            <b-col cols="12" md="4" v-for="(item, index) in numbs" :key="index">
              <div class="numb">
                <b-row align-h="center" align-v="center">
                  <b-col cols="3">
                    <p class="numb_index">{{ ++index }}</p>
                  </b-col>

                  <b-col cols="9">
                    <p class="numb_text">{{ item.text }}</p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-row align-h="center">
          <b-col cols="12" md="8">
            <h4 class="text-center">
              Если хотя бы одно из этих утверждений описывает тебя<br />
              - мы нашли причину, почему ты не достиг цели, и знаем как тебе
              помочь
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--block_d-->
    <div id="platformAbout" class="block_d">
      <b-container>
        <b-row class="row_d">
          <b-col cols="12" md="1"></b-col>

          <b-col cols="12" md="5">
            <p class="text_d">
              «Данную платформу можно сравнить с тренажерным залом. С тем лишь
              отличием, что в зале ты работаешь со своим телом, а на платформе -
              со своим состоянием, мыслямиицелями».
            </p>
          </b-col>

          <b-container>
            <b-row>
              <b-col cols="12" md="1"></b-col>

              <b-col cols="12" md="5">
                <div class="media">
                  <img
                    class="align-self-start mr-3"
                    src="../assets/img/LandingPage/pointing.png"
                    alt=""
                  />

                  <div class="media-body">
                    <p class="name_d">
                      Федор Купряков<br />
                      основатель Платформы БК
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
    </div>

    <div class="block_d2">
      <b-container>
        <b-row align-v="center" align-h="between">
          <b-col cols="12" md="5" class="tx">
            <img src="../assets/img/LandingPage/cup.png" width="100%" alt="" />
          </b-col>

          <b-col cols="12" md="auto" class="tx">
            <h3 class="text_d2">
              КАКОГО РЕЗУЛЬТАТА<br />
              <span class="red">ТЫ ДОСТИГНЕШЬ С НАМИ</span>
            </h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6" class="tx">
            <div class="pict_l media">
              <div class="img_d1 align-self-start"></div>

              <div class="media-body">
                <p class="pict_name">
                  Платформа привьёт привычки думать и принимать решения за
                  первые 30 дней
                </p>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="6" class="tx">
            <div class="pict media">
              <div class="img_d2 align-self-start"></div>
              <div class="media-body">
                <p class="pict_name">
                  Ты проработаешь 10 инструментов увеличения своего дохода за
                  первые 2 недели
                </p>
              </div>
            </div>
          </b-col>

          <div class="tx col-md-6">
            <div class="pict_l media">
              <div class="img_d3 align-self-start"></div>
              <div class="media-body">
                <p class="pict_name">
                  Пропишешь и структурируешь свои цели в первые 3 дня
                </p>
              </div>
            </div>
          </div>

          <div class="tx col-md-6">
            <div class="pict media">
              <div class="img_d4 align-self-center"></div>
              <div class="media-body">
                <p class="pict_name">
                  Ты органично встроешь в свою жизнь ежедневные ритуалы
                  долларовых миллионеров
                </p>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <!-- block_e -->

    <div class="block_e">
      <div class="container">
        <div class="title_e row d-flex justify-content-center align-self-start">
          <div class="col-md-9">
            <h3>
              ЭТУ СИСТЕМУ ИСПОЛЬЗУЮТ ДЛЯ СВОЕГО НЕПРЕРЫВНОГО РАЗВИТИЯ
              <span class="red">УЖЕ БОЛЬШЕ 200 ПОЛЬЗОВАТЕЛЕЙ</span>
            </h3>
          </div>
        </div>

        <div
          class="description row d-flex justify-content-center align-self-start"
        >
          <div class="video_e col-md">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/7hddNQHeiaU"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <img
              class="line_s"
              src="../assets/img/LandingPage/line_s.png"
              alt=""
            />
            <p class="username">Иван Голощёков</p>
            <p>Точка А: Работа по найму, ЗП - 80К</p>
          </div>

          <div class="video_e col-md">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/ykdvcgeIOr4"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <img
              class="line_s"
              src="../assets/img/LandingPage/line_s.png"
              alt=""
            />
            <p class="username">Мария Рубироид</p>
            <p>Точка А: Работа по найму, ЗП - 80К</p>
          </div>

          <div class="video_e col-md">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/REIS2dnDlVk"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <img
              class="line_s"
              src="../assets/img/LandingPage/line_s.png"
              alt=""
            />
            <p class="username">Роман Трахтенберг</p>
            <p>Точка А: Работа по найму, ЗП - 80К</p>
          </div>
        </div>
      </div>
    </div>

    <!-- block_f -->

    <div class="block_f">
      <b-container>
        <div class="text_f row d-flex justify-content-start align-self-start">
          <b-col cols="12" md="7">
            <h3 class="title_f">
              Это истории реальных<br />
              людей, которые<br />
              <span class="red"
                >решили разделить<br />
                свой путь с нами</span
              >
            </h3>

            <h4>Хочешь так же? Присоединяйся</h4>
          </b-col>
        </div>

        <b-row class="txt_btn_f" align-v="center">
          <b-col cols="12" md="3">
            <form>
              <button
                class="welcome_button"
                @click="$router.push({ name: 'Tasks' })"
              >
                Начать изменения
              </button>
            </form>
          </b-col>

          <b-col cols="12" md="3">
            <p class="p_small">
              Попробуй прямо сейчас и получи<br />
              <span class="red">бесплатный доступ к платформе на 7 дней</span>
            </p>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="12" md="auto" class="title_f2">
            <h3>
              Что поможет достичь<br />
              тебе такого результата?
            </h3>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="12" md="6" class="desc_f">
            <p>
              Ежедневное 10-15 минутное общение с долларовыми миллионерами через
              их книги.
            </p>

            <p>Выполнение практических заданий.</p>

            <p>Постановка целей по технологии Agile.</p>

            <p>Встроенная геймификация, контроль модераторов платформы.</p>

            <p>
              Ведение дневника побед и ежедневный фокус внимания на своих
              победах.
            </p>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col cols="12" md="auto">
            <h5 class="subtitle_f">Платформа поможет поставить 100+ целей</h5>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- block_g -->

    <div>
      <b-container fluid>
        <b-row align-h="center" class="block_g" align-v="center">
          <b-col cols="12" md="7">
            <div class="block_g__bg_wrapper">
              <img
                class="block_g__bg_img"
                :src="require('@/assets/img/LandingPage/background_g.png')"
              />
            </div>
          </b-col>

          <b-col cols="12" md="5">
            <h3 class="title_g">На чем основана наша технология?</h3>

            <p class="text_g">
              Главный инструмент Платформы БК — это система из ритуалов и
              игровых механик, которая позволит тебе перенять картину мира
              долларовых миллионеров, и создать свой успех, опираясь на силу
              своей личности, по формуле Быть - Делать - Иметь.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- block_h -->
    <!--
    <div class="block_h">
      <div class="container">

              <div class="row d-flex justify-content-center align-self-start">
                <h3 class="title_h">не знаешь кто такие<br> 
                  <span class="red">грант Кардон и Майкл Роуч?</span></h3>
              </div>

              <div class="content_h row d-flex justify-content-end align-self-end">

                <div class="col-md-1"></div>
                
                <div class="col_img_h col-md-4 align-self-center">
                  <img src="../assets/img/LandingPage/arrow.png" alt="" width="100%">
                </div>

                <div class="col-md-1"></div>

                <div class="col-md-6 align-self-center">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item"  src="https://www.youtube.com/embed/AUdrz079v80" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>

              </div>
      </div>
    </div>-->

    <!-- block_i -->

    <div class="block_i d-none">
      <b-container>
        <b-row class="content_i" align-h="center">
          <h6 class="title_i">Дополнительно ты получишь:</h6>
        </b-row>

        <b-row class="row_i" align-h="center">
          <div class="i_left col-md-4">
            <p class="block_i__text">
              <span class="bold_txt">Состояние авторства - </span>умение
              управлять свей жизнью, брать ответственность за свой день
            </p>
          </div>

          <div class="dot_center col-md-1"></div>

          <div class="i_right col-md-4"></div>
        </b-row>

        <div class="row_i row d-flex justify-content-center align-self-start">
          <div class="i_left col-md-4"></div>

          <div class="dot_center col-md-1"></div>

          <div class="i_right col-md-4">
            <p class="block_i__text">
              <span class="bold_txt">Усиленный в 365 раз фокус</span><br />
              на действительно важных для<br />
              тебя целях
            </p>
          </div>
        </div>

        <div class="row_i row d-flex justify-content-center align-self-start">
          <div class="i_left col-md-4">
            <p class="block_i__text">
              <span class="bold_txt">Веру в себя - </span>веру в то, что ты
              лучший и достоен лучшей жизни
            </p>
          </div>

          <div class="dot_center col-md-1"></div>

          <div class="i_right col-md-4"></div>
        </div>

        <div class="row_i row d-flex justify-content-center align-self-start">
          <div class="i_left col-md-4"></div>

          <div class="dot_center_last col-md-1"></div>

          <div class="i_right col-md-4">
            <p class="block_i__text">
              <span class="bold_txt">Фокус на победе</span> - просыпатся и
              засыпать<br />
              с мыслями о целях, получать от<br />
              них энергию и заряд
            </p>
          </div>
        </div>
      </b-container>
    </div>

    <!-- block_k -->
    <!--
    <div class="block_k">
      <div class="container">
        <div class="row justify-content-center">
          <div class="block_k_title col-md-6">
            <h3>мы начинали так же,<br>
              <span class="red">как и ты</span>
               </h3>
          </div>
        </div>

        <div class="col_k_people1 row d-flex justify-content-center align-items-start">
          <div class="col-md-5">
            <img src="../assets/img/LandingPage/people1.png" alt="">
          </div>
          <div class="col-md-1">
          </div>
          <div class="col_k_text1 col-md-6">
            <p>Идейные соображения высшего порядка, а также дальнейшее развитие различных форм деятельности позволяет оценить значение форм развития. Идейные соображения высшего порядка, а также постоянное информационно-пропагандистское обеспечение нашей деятельности в значительной степени обуславливает создание форм развития. 

              С другой стороны реализация намеченных плановых заданий позволяет оценить значение направлений прогрессивного развития.
              
              Задача организации, в особенности же рамки и место обучения кадров обеспечивает широкому кругу (специалистов) участие в формировании соответствующий условий активизации. Значимость этих проблем настолько очевидна, что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития. С другой стороны реализация намеченных плановых заданий требуют определения и уточнения дальнейших направлений развития. Не следует, однако забывать, что сложившаяся структура организации требуют от нас анализа модели развития..</p>
          </div>
        </div>

        <div class="col_k_people2 row d-flex justify-content-center align-items-start">
          <div class="col_k_text2 col-md-6">
            <p>Идейные соображения высшего порядка, а также дальнейшее развитие различных форм деятельности позволяет оценить значение форм развития. Идейные соображения высшего порядка, а также постоянное информационно-пропагандистское обеспечение нашей деятельности в значительной степени обуславливает создание форм развития. 

              С другой стороны реализация намеченных плановых заданий позволяет оценить значение направлений прогрессивного развития.

              Задача организации, в особенности же рамки и место обучения кадров обеспечивает широкому кругу (специалистов) участие в формировании соответствующий условий активизации. Значимость этих проблем настолько очевидна, что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития. С другой стороны реализация намеченных плановых заданий требуют определения и уточнения дальнейших направлений развития. Не следует, однако забывать, что сложившаяся структура организации требуют от нас анализа модели развития..</p>
          </div>
          <div class="col-md-1">
            
          </div>
          <div class="col-md-5">
            <img src="../assets/img/LandingPage/people2.png" alt="">
          </div>
        </div>

      </div>
    </div>-->

    <!-- block_l -->

    <div class="block_l">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <h3 class="block_l_title1">
              в чем уникальность нашей
              <span class="red">платформы?</span>
            </h3>
          </div>
        </div>

        <div class="row_l2 row justify-content-end">
          <div class="col-md-4">
            <p class="l_text_1">
              Платформа не столько учит, сколько помогает делать.
            </p>
            <p class="l_text_1">
              Платформа влияет на твоё состояние в моменте и в долгосрочной
              перспективе
            </p>
            <p class="l_text_1">Платформа наглядно показывает твой прогресс.</p>
          </div>
        </div>

        <div class="row_l3 row justify-content-start">
          <div class="col-md-6">
            <h3 class="l_text_2">
              Но помни: чтобы<br />
              получить результат,<br />
            </h3>
            <h1 class="l_subtitle"><span class="red">надо делать</span></h1>
            <p>
              Ты решаешь сам, а мы лишь превращаем<br />
              твои мысли в конкретные действия.
            </p>
          </div>
        </div>

        <b-row class="block_l_title2" align-h="center">
          <b-col cols="12" md="auto">
            <h3><span class="red">тарифы</span></h3>
          </b-col>
        </b-row>

        <b-row v-if="subscribes">
          <b-col
            cols="12"
            md="4"
            v-for="(sub, index) in subscribes"
            :key="index"
            class="subscription_card_wrapper"
          >
            <SubCard :item="sub" startChange :index="index" />
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- <div class="quest">
      <div class="container">
        <div class="row justify-content-center align-items-start">
          <div class="col-md-9">
            <h3>часто задаваемые <span class="red">вопросы</span></h3>
          </div>
        </div>
        <div class="row justify-content-center align-items-start">
          <div class="quest_col col-md-9">
            <p>Есть ли партнерская программа вашей платформы?</p>
          </div>
          <div class="quest_col col-md-9">
            <p>Есть ли партнерская программа вашей платформы?</p>
          </div>
          <div class="quest_col col-md-9">
            <p>Есть ли партнерская программа вашей платформы?</p>
          </div>
          <div class="quest_col col-md-9">
            <p>Есть ли партнерская программа вашей платформы?</p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- block_m -->

    <div class="block_m">
      <img
        class="row_m_form__img"
        :src="require('@/assets/img/LandingPage/rocket.png')"
        alt=""
      />

      <b-container>
        <b-row align-h="center">
          <b-col cols="12" md="9" class="m_title">
            <h3>Призыва к действию не будет. Будет только твой выбор.</h3>
          </b-col>

          <b-col cols="12" md="9" class="m_title2">
            <h1><span class="red">РЕШАЙ</span></h1>
          </b-col>
        </b-row>

        <b-row class="row_m_form" align-v="center" align-h="center">
          <b-col cols="12" md="5" class="col-md-5">
            <div class="form_bottom">
              <form>
                <div class="form-group">
                  <label for="name">Имя</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    aria-describedby="nameHelp"
                    placeholder="Иван"
                    name="name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="pochta@mail.ru"
                    name="email"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">Телефон</label>
                  <input
                    type="phone"
                    class="form-control"
                    id="phone"
                    placeholder="+ 7 (900) 123-45-67"
                    name="phone"
                    required
                  />
                </div>

                <button class="welcome_button">Подробнее</button>

                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    required
                  />
                  <label class="m_check form-check-label" for="exampleCheck1"
                    >Я даю своё согласие на обработку персональных данных и
                    соглашаюсь с условиями
                    <a
                      @click="$router.push({ name: 'TermsOfUse' })"
                      class="red terms_link"
                      >политики конфиденциальности</a
                    ></label
                  >
                </div>
              </form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
require("../scss/LandingPage/md_style.css");
require("../scss/LandingPage/sm_style.css");
require("../scss/LandingPage/style.css");

import { mapActions } from "vuex";
import SubCard from "../components/bkonline/SubCard.vue";

export default {
  components: {
    SubCard,
  },
  data() {
    return {
      numbs: [
        {
          text: "Считают, что у них и так всё хорошо, лучше жить по вдохновению.",
        },
        {
          text: "Не ставят цели вовсе или ставят раз в год под бокал шампанского.",
        },
        {
          text: "Ставят не амбизиозные, а посредственные цели, которые их не вдохновляют.",
        },
        {
          text: "Не оживляют свои цели действиями и забывают о них через пару дней.",
        },
        {
          text: "Переоценивают свои силы, решив, что смогут достичь поставленных целей.",
        },
        {
          text: "Планируя свой день, не учитывают свои цели.",
        },
      ],
    };
  },
  computed: {
    subscribes() {
      return this.$store.state.bkonline.subscriptionTypes;
    },
  },
  methods: {
    ...mapActions(["getSubscriptionTypes", "getUser"]),
  },

  async mounted() {
    document.body.setAttribute("viewnav", false);
    document.body.setAttribute("viewbpadding", false);

    await this.getSubscriptionTypes();

    if (localStorage.getItem("token")) {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    }
  },
};
</script>

<style lang="scss">
.numb_text {
  color: $black;
}
.numb_index {
  color: $accent;
  font-size: 110px;
  font-weight: 700;
}
.numb {
  background-color: #ffffff;
  padding: 30px;
  height: 220px;
  margin-bottom: 30px;
}

.numb:hover {
  box-shadow: -10px -10px 0px rgba(241, 11, 11, 1);
}

.card_numb {
  margin-bottom: 110px;
}

.block_g {
  position: relative;
  background-color: black;
  color: white;
}
.block_g__bg_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.block_g__bg_img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}
</style>
